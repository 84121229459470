import Diseases from "./diseases.service";

class Disease {
  constructor(payload = {}) {
    Object.assign(this, {
      ...Disease.build(payload),
    });
  }

  static build({
    id = "",
    name = "",
    created_at = "",
    updated_at = ""
  }) {
    return {
      id,
      name,
      created_at,
      updated_at,
    };
  }

  static newData(payload) {
    return new Disease(payload);
  }

  static list(params) {
    return new Promise((resolve, reject) => {
      Diseases.list(params).then(
        (success) => {
          const { data } = success;
          const list = Object.assign({}, { ...data });
          list.data = data.data.map((item) => this.newData(item));
          resolve(list);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  static listByField(fieldId) {
    return new Promise((resolve, reject) => {
      Diseases.listByField(fieldId).then(
        (success) => {
          const { data } = success;
          const list = Object.assign({}, { ...data });
          list.data = data.data.map((item) => this.newData(item));
          resolve(list);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  static listNonResistantByCultivar(cultivarId, fieldId) {
    return new Promise((resolve, reject) => {
      Diseases.listNonResistantByCultivar(cultivarId, fieldId).then(
        (success) => {
          const { data } = success;
          const list = Object.assign({}, { ...data });
          list.data = data.data.map((item) => this.newData(item));
          resolve(list);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}

export default Disease;
