import DiseaseBehaviors from "./disease-behaviors.service";

class DiseaseBehavior {
  constructor(payload = {}) {
    Object.assign(this, {
      ...DiseaseBehavior.build(payload),
    });
  }

  static build({
    id = "",
    disease_id = "",
    disease = {},
    resistance_scale = "",
    cultivar_id = "",
    cultivar = {},
    created_at = "",
    updated_at = ""
  }) {
    return {
      id,
      disease_id,
      disease,
      resistance_scale,
      cultivar_id,
      cultivar,
      created_at,
      updated_at,
    };
  }

  static newData(payload) {
    return new DiseaseBehavior(payload);
  }

  static listByCultivar(cultivarId) {
    return new Promise((resolve, reject) => {
      DiseaseBehaviors.listByCultivar(cultivarId).then(
        (success) => {
          const { data } = success;
          const list = Object.assign({}, { ...data });
          list.data = data.data.map((item) => this.newData(item));
          resolve(list);
        },

        (error) => {
          reject(error);
        }
      );
    });
  }

  static create(payload) {
    return new Promise((resolve, reject) => {
      DiseaseBehaviors.create(payload).then(
        (success) => {
          resolve(success);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  static edit(payload = {}) {
    return new Promise((resolve, reject) => {
      DiseaseBehaviors.edit(payload).then(
        (success) => {
          resolve(success);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  static delete(payload) {
    return new Promise((resolve, reject) => {
      DiseaseBehaviors.delete(payload).then(success => {
        resolve(success);
      }, error => {
        reject(error);
      });
    });
  }
}

export default DiseaseBehavior;
