import http from "@/services/http.service";

const apiUrl = "/disease-behaviors";

class SowingSeasonsService {
  static create(payload) {
    return http.post(`${apiUrl}`, payload);
  }
  static edit(payload) {
    return http.put(`${apiUrl}/${payload.id}`, payload);
  }
  static get(id) {
    return http.get(`${apiUrl}/${id}`);
  }
  static listByCultivar(cultivarId) {
    return http.get(`${apiUrl}/cultivars/${cultivarId}`);
  }
  static delete(payload) {
    return http.delete(`${apiUrl}/${payload.id}`);
  }
}

export default SowingSeasonsService;
