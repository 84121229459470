<template>
  <div>
    <form-dialog :dialog="formDialog" :formName="dynamicFormName">
      <v-form ref="formDiseaseBehavior">
        <v-row>
          <v-col cols="12" md="4" sm="6">
            <custom-label18 label="Informações Gerais"></custom-label18>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="Nome da Doença"/>
              <simple-select
                v-model="payload.disease_id"
                :items="diseases"
                itemText="name"
                itemValue="id"
                class="mr-2"
                placeholder="Selecione o nome de uma doença"
                :menuProps="{ bottom: true, offsetY: true, closeOnClick: true, maxHeight: 200 }"
                required
                :rules="requiredRules"
              />
          </v-col>

          <v-col cols="12" md="4" sm="6">
            <custom-label label="Categoria"></custom-label>
            <simple-select
              v-model="payload.resistance_scale"
              :items="resistance_scale"
              itemText="text"
              itemValue="text"
              class="mr-2"
              placeholder="Selecione uma categoria"
              :menuProps="{ bottom: true, offsetY: true, closeOnClick: true, maxHeight: 200 }"
              required
              :rules="requiredRules"
              />
          </v-col>
        </v-row>
      </v-form>
    </form-dialog>

    <v-row align="center">
      <v-col class="d-flex justify-start">
        <span class="font-weight-bold">Comportamento de Doenças</span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="d-flex justify-end">
        <primary-button
          label="+ Adicionar"
          type="button"
          @callAction="create"
        />
      </v-col>
    </v-row>

    <data-table :headers="headers"
                :fetching="fetching"
                :data="data"
                :objectName="objectName"
                @loadAgain="loadAgain"
                class="data-table-disease-behaviors"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon style="color:#707070;" class="ml-4" small @click="view(item)">
          mdi-information-outline
        </v-icon>

        <v-icon style="color:#707070;" class="ml-4" small @click="edit(item)">
          mdi-pencil
        </v-icon>

        <v-icon class="ml-4" small @click="deleteItem(item)">
          mdi-trash-can-outline
        </v-icon>
      </template>
    </data-table>

    <confirm-destroy-dialog
      :title="'este comportamento de doença'"
      :dialog.sync="showConfirmDestroyDialog"
      @closeDialog="closeConfirmDestroyDialog"
    />
  </div>
</template>

<script>
  import FormDialog from "@/components/crud/FormDialog.vue";
  import SimpleSelect from "@/components/inputs/SimpleSelect.vue";
  import DataTable from "@/components/data-table/DataTable.vue";
  import CustomLabel from "@/components/texts/CustomLabel";
  import PrimaryButton from "@/components/buttons/PrimaryButton.vue";
  import CustomLabel18 from "@/components/texts/CustomLabel18.vue";
  import ConfirmDestroyDialog from "@/components/dialogs/ConfirmDestroyDialog";

  import DiseaseBehaviors from "@/domain/disease-behaviors/disease-behaviors.js";
  import Diseases from "@/domain/diseases/diseases.js";

  export default {
    name: 'DiseaseBehaviors',

    props: ['cultivar_id'],

    components: {
      FormDialog,
      SimpleSelect,
      DataTable,
      CustomLabel,
      PrimaryButton,
      CustomLabel18,
      ConfirmDestroyDialog
    },

    data() {
      return {
        openDetails: false,
        formDialog: false,
        formName: 'Adicionar Comportamento',
        objectName: 'comportamento de doenças',

        headers: [
          {text: 'Nome', align: 'start', value: 'disease.name'},
          {text: 'Escala de Resistência', value: 'resistance_scale'},
          {text: 'Detalhes', value: 'actions', sortable: false}
        ],

        data: [],
        diseases: [],
        payload: {},
        fetching: true,
        diseaseBehaviorService: null,
        diseaseService: null,

        showConfirmDestroyDialog: false,

        resistance_scale: [
          {text : 'Resistente'},
          {text : 'Moderadamente Resistente'},
          {text : 'Suscetível'},
          {text : 'Moderadamente Suscetível'},
          {text : 'Tolerante'},
        ],

        requiredRules: [v => (!!v || v === false) || "O campo é obrigatório"]
      };
    },

    methods: {
      getDiseaseBehaviors() {
        this.fetching = true;
        this.data = [];

        this.diseaseBehaviorService.listByCultivar(this.cultivar_id).then(result => {
          result.data.map(item => {
            this.data.push(item);
          });
        });

        this.fetching = false;
      },

      getDiseases() {
        this.fetching = true;
        this.diseases = [];

        this.diseaseService.list().then(result => {
          result.data.map(item => {
            this.diseases.push(item);
          });
        });

        this.fetching = false;
      },

      initialize() {
        this.diseaseBehaviorService = DiseaseBehaviors;
        this.diseaseService = Diseases;
        this.payload = this.diseaseBehaviorService.newData();

        this.getDiseaseBehaviors();
        this.getDiseases();
      },

      view(item) {
        this.payload = Object.assign({}, item);
        this.$emit('disease-behavior-details', this.payload);
      },

      create() {
        this.payload = {};
        this.formDialog = true;
      },

      edit(item) {
        this.payload = Object.assign({}, item);
        this.formDialog = true;
        this.openDetails = true;
      },

      deleteItem(item) {
        this.payload = Object.assign({}, item);
        this.showConfirmDestroyDialog = true;
      },

      closeConfirmDestroyDialog() {
        this.showConfirmDestroyDialog = false;
      },

      closeFormDialog() {
        this.formDialog = false;
        this.openDetails = false;
      },

      save() {
        if (!this.$refs.formDiseaseBehavior.validate()) {
          return;
        }

        this.payload.cultivar_id = this.cultivar_id;

        let loader = this.$loading.show();

        if (!this.openDetails) {
          this.diseaseBehaviorService.create(this.payload)
          .then(() => {
            loader.hide();
            this.closeFormDialog();
            this.initialize();
            this.$toasted.show('Comportamento de doença cadastrado com sucesso.', {
              type: 'success'
            });
          })
          .catch(({response}) => {
            loader.hide();
            const {error} = response.data;
            this.$toasted.show(error, {
              type: 'error'
            });
          });
        } else {
          this.diseaseBehaviorService.edit(this.payload)
          .then(() => {
            loader.hide();
            this.initialize();
            this.closeFormDialog();

            this.$toasted.show('Comportamento de doença atualizado com sucesso.', {
              type: 'success'
            });
          })
          .catch(({response}) => {
            loader.hide();
            const {error} = response.data;
            this.$toasted.show(error, {
              type: 'error'
            });
          });
        }
      },

      async destroy() {
        const loader = this.$loading.show();

        await this.diseaseBehaviorService.delete(this.payload).then(() => {
          loader.hide();
          this.getDiseaseBehaviors();
          this.showConfirmDestroyDialog = false;
          this.$toasted.show("Comportamento de doença removido com sucesso.", {
            type: "success",
          });
        })
        .catch(() => {
          loader.hide();
          this.showConfirmDestroyDialog = false;
          this.$toasted.show("Comportamento de doença não foi removido.", {
            type: 'error'
          });
        });
      },

      loadAgain() {
        this.initialize();
      }
    },

    computed: {
      dynamicFormName() {
        if (this.openDetails) {
          return "Editar Comportamento";
        }

        return this.formName;
      }
    },

    beforeMount() {
      this.initialize();
    }
  }
</script>

<style scoped>
  .data-table-disease-behaviors {
    border: none !important;
    padding: 0 !important;
    margin-top: 1rem;
  }

  ::v-deep .data-table-disease-behaviors th:last-of-type,
  ::v-deep .data-table-disease-behaviors td:last-of-type {
    text-align: center !important;
  }
</style>
