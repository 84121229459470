import SowingSeasons from "./sowing-seasons.service";

class SowingSeason {
  constructor(payload = {}) {
    Object.assign(this, {
      ...SowingSeason.build(payload),
    });
  }

  static build({
    id = "",
    region = "",
    sub_region = "",
    min_population = "",
    max_population = "",
    medium_cycle = "",
    start_pre_acceptable_period = "",
    end_pre_acceptable_period = "",
    start_recommended_period,
    end_recommended_period = "",
    start_post_acceptable_period = "",
    end_post_acceptable_period = "",
    cultivar_id = "",
    cultivar = {},
    created_at = "",
    updated_at = "",
  }) {
    return {
      id,
      region,
      sub_region,
      min_population,
      max_population,
      medium_cycle,
      start_pre_acceptable_period,
      end_pre_acceptable_period,
      start_recommended_period,
      end_recommended_period,
      start_post_acceptable_period,
      end_post_acceptable_period,
      cultivar_id,
      cultivar,
      created_at,
      updated_at,
    };
  }

  static newData(payload) {
    return new SowingSeason(payload);
  }

  static listByCultivar(cultivarId) {
    return new Promise((resolve, reject) => {
      SowingSeasons.listByCultivar(cultivarId).then(
        success => {
          const { data } = success;
          const list = Object.assign({}, { ...data });
          list.data = data.data.map((item) => this.newData(item));
          resolve(list);
        },

        error => {
          reject(error);
        }
      );
    });
  }

  static create(payload) {
    return new Promise((resolve, reject) => {
      SowingSeasons.create(payload).then(
        (success) => {
          resolve(success);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  static edit(payload = {}) {
    return new Promise((resolve, reject) => {
      SowingSeasons.edit(payload).then(
        (success) => {
          resolve(success);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  static listStoredRegionsByCultivar(cultivarId) {
    return new Promise((resolve, reject) => {
      SowingSeasons.listStoredRegionsByCultivar(cultivarId).then(
        success => {
          const { data } = success;
          const list = Object.assign({}, { ...data });
          resolve(list);
        },

        error => {
          reject(error);
        }
      );
    });
  }

  static listStoredSubRegionsByCultivar(cultivarId) {
    return new Promise((resolve, reject) => {
      SowingSeasons.listStoredSubRegionsByCultivar(cultivarId).then(
        success => {
          const { data } = success;
          const list = Object.assign({}, { ...data });
          resolve(list);
        },

        error => {
          reject(error);
        }
      );
    });
  }

  static delete(payload) {
    return new Promise((resolve, reject) => {
      SowingSeasons.delete(payload).then(success => {
        resolve(success);
      }, error => {
        reject(error);
      });
    });
  }
}

export default SowingSeason;
