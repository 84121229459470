<template>
  <div>
    <form-dialog :dialog="formDialog" :formName="dynamicFormName">
      <v-form ref="formSowingSeason">
        <v-row>
          <v-col cols="12" md="4" sm="6">
            <custom-label18 label="Informações Gerais"></custom-label18>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="Região"></custom-label>
            <simple-select
              v-model="payload.region"
              :items="regions"
              itemText="text"
              itemValue="text"
              class="mr-2"
              placeholder="Selecione uma região"
              @custom-change="changeRegion()"
              style="width: 91%;"
              :menuProps="{ bottom: true, offsetY: true, closeOnClick: true, maxHeight: 200 }"
              required
              :rules="requiredRules"
              >
            </simple-select>
          </v-col>

          <v-col cols="12" md="4" sm="6">
            <custom-label label="Sub-Região"></custom-label>
            <simple-select
              :key="componentKey"
              v-model="payload.sub_region"
              :items="subRegionFiltered"
              itemText="text"
              itemValue="text"
              class="mr-2"
              placeholder="Selecione uma sub-região"
              :disabled="!regionSelected"
              style="width: 91%;"
              :menuProps="{ bottom: true, offsetY: true, closeOnClick: true, maxHeight: 200 }"
              required
              :rules="requiredRules"
              >
            </simple-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="2" sm="6">
            <custom-label label="População Mín."/>
              <div>
                <simple-text-field v-model="payload.min_population"
                                  required
                                  onlyPositiveNumber
                                   mask="index"
                                  style="display: inline-block; width: 60%;"/>
                <custom-label label="Mil" class="ml-2"/>
              </div>
          </v-col>

          <v-col cols="12" md="2" sm="6">
            <custom-label label="População Máx."/>
              <div>
                <simple-text-field v-model="payload.max_population"
                                  required
                                  onlyPositiveNumber
                                   mask="index"
                                  style="display: inline-block; width: 60%;"/>
                <custom-label label="Mil" class="ml-2"/>
              </div>
          </v-col>

          <v-col cols="12" md="2" sm="6">
            <custom-label label="Ciclo Médio"/>
              <div>
                <simple-text-field v-model="payload.medium_cycle"
                                  required
                                  onlyPositiveNumber
                                   mask="index"
                                  style="display: inline-block; width: 68%;"/>
                <custom-label label="Dias" class="ml-2"/>
              </div>
          </v-col>
        </v-row>

        <v-row class="mt-10">
          <v-col cols="12" md="4" sm="6">
            <custom-label18 label="Período Pré Aceitável"></custom-label18>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="Data de Início"/>
            <date-picker v-model="payload.start_pre_acceptable_period"
                              style="width: 91%;"
                              required
                              placeholder="dd/mm"/>
          </v-col>

          <v-col cols="12" md="4" sm="6">
            <custom-label label="Data do Fim"/>
            <date-picker v-model="payload.end_pre_acceptable_period"
                              placeholder="dd/mm"
                              style="width: 91%;"
                              required/>
          </v-col>
        </v-row>

        <v-row class="mt-4">
          <v-col cols="12" md="4" sm="6">
            <custom-label18 label="Período Recomendado"></custom-label18>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="Data de Início"/>
            <date-picker v-model="payload.start_recommended_period"
                              placeholder="dd/mm"
                              style="width: 91%;"
                              required/>
          </v-col>

          <v-col cols="12" md="4" sm="6">
            <custom-label label="Data do Fim"/>
            <date-picker v-model="payload.end_recommended_period"
                              placeholder="dd/mm"
                              style="width: 91%;"
                              required/>
          </v-col>
        </v-row>

        <v-row class="mt-4">
          <v-col cols="12" md="4" sm="6">
            <custom-label18 label="Período Pós Aceitável"></custom-label18>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="Data de Início"/>
            <date-picker v-model="payload.start_post_acceptable_period"
                              placeholder="dd/mm"
                              style="width: 91%;"
                              required/>
          </v-col>

          <v-col cols="12" md="4" sm="6">
            <custom-label label="Data do Fim"/>
            <date-picker v-model="payload.end_post_acceptable_period"
                              placeholder="dd/mm"
                              style="width: 91%;"
                              required/>
          </v-col>
        </v-row>
      </v-form>
    </form-dialog>

    <v-row align="center">
      <v-col class="d-flex justify-start">
        <span class="font-weight-bold">Época de Semeadura</span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="d-flex justify-end">
        <primary-button
          label="+ Adicionar"
          type="button"
          @callAction="create"
        />
      </v-col>
    </v-row>

    <data-table :headers="headers"
                :fetching="fetching"
                :data="data"
                :objectName="objectName"
                @loadAgain="loadAgain"
                class="data-table-sowing-seasons"
      >
      <template v-slot:[`item.min_population`]="{ item }">
        {{ item.min_population + ' mil' }}
      </template>

      <template v-slot:[`item.max_population`]="{ item }">
        {{ item.max_population + ' mil' }}
      </template>

      <template v-slot:[`item.medium_cycle`]="{ item }">
        {{ item.medium_cycle + ' dias' }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon style="color:#707070;" class="ml-4" small @click="view(item)">
          mdi-information-outline
        </v-icon>

        <v-icon style="color:#707070;" class="ml-4" small @click="edit(item)">
          mdi-pencil
        </v-icon>

        <v-icon class="ml-4" small @click="deleteItem(item)">
          mdi-trash-can-outline
        </v-icon>
      </template>
    </data-table>

    <confirm-destroy-dialog
      :title="'esta época de semeadura'"
      :dialog.sync="showConfirmDestroyDialog"
      @closeDialog="closeConfirmDestroyDialog"
    />
  </div>
</template>

<script>
  import FormDialog from "@/components/crud/FormDialog.vue";
  import SimpleSelect from "@/components/inputs/SimpleSelect.vue";
  import DataTable from "@/components/data-table/DataTable.vue";
  import SimpleTextField from "@/components/inputs/SimpleTextField.vue";
  import CustomLabel from "@/components/texts/CustomLabel";
  import PrimaryButton from "@/components/buttons/PrimaryButton.vue";
  import CustomLabel18 from "@/components/texts/CustomLabel18.vue";
  import ConfirmDestroyDialog from "@/components/dialogs/ConfirmDestroyDialog";
  import DatePicker from '@/components/inputs/DateAndMonthPicker.vue'

  import SowingSeasons from "@/domain/sowing-seasons/sowing-seasons.js";

  export default {
    name: 'SowingSeasons',

    props: ['cultivar_id'],

    components: {
      FormDialog,
      SimpleSelect,
      DataTable,
      SimpleTextField,
      CustomLabel,
      PrimaryButton,
      CustomLabel18,
      ConfirmDestroyDialog,
      DatePicker
    },

    data() {
      return {
        openDetails: false,
        formDialog: false,
        formName: 'Adicionar Época',
        objectName: 'épocas',

        headers: [
          {text: 'Região', align: 'start', value: 'region'},
          {text: 'Sub-Região', value: 'sub_region'},
          {text: 'População Mín.', value: 'min_population'},
          {text: 'População Máx.', value: 'max_population'},
          {text: 'Ciclo', value: 'medium_cycle'},
          {text: 'Detalhes', value: 'actions', sortable: false}
        ],

        data: [],
        payload: {},
        fetching: true,
        sowingSeasonService: null,
        regionSelected: false,
        subRegionFiltered: [],
        storedSubRegions: [],
        subRegionEdit: "",
        componentKey: 0,

        showConfirmDestroyDialog: false,

        regions: [
          {text: 'M01'}, {text: 'M02'}, {text: 'M03'}, {text: 'M04'}, {text: 'M05'},
        ],

        subRegions: [
          {
            'M01' : [ {text: 101}, {text: 102}, {text: 103}, {text: 104} ]
          },

          {
            'M02' : [ {text: 201}, {text: 202}, {text: 203}, {text: 204} ]
          },

          {
            'M03' : [ {text: 301}, {text: 302}, {text: 303}, {text: 304} ]
          },

          {
            'M04' : [ {text: 401}, {text: 402}, {text: 403}, {text: 404}, {text: 405} ]
          },

          {
            'M05' : [ {text: 501}, {text: 502}, {text: 503} ]
          }
        ],

        requiredRules: [v => (!!v || v === false) || "O campo é obrigatório"]
      };
    },

    methods: {
      onlyNumbers() {
      },

      getSowingSeasons() {
        this.fetching = true;
        this.data = [];

        this.sowingSeasonService.listByCultivar(this.cultivar_id).then(result => {
          result.data.map(item => {
            this.data.push(item);
          });
        });

        this.fetching = false;
      },

      getStoredSubRegionsByCultivar() {
        let loader = this.$loading.show();
        this.storedSubRegions = [];

        this.sowingSeasonService
        .listStoredSubRegionsByCultivar(this.cultivar_id)
        .then(result => {
          loader.hide();
          result.data.map(item => {
            this.storedSubRegions.push(item);
          });
        })
        .catch(({response}) => {
          loader.hide();
          const {error} = response.data;
          this.$toasted.show(error, {
            type: "error",
          });
        });
      },

      initialize() {
        this.sowingSeasonService = SowingSeasons;
        this.payload = this.sowingSeasonService.newData();
        this.regionSelected = false;

        this.getSowingSeasons();
        this.getStoredSubRegionsByCultivar();
      },

      view(item) {
        this.payload = Object.assign({}, item);
        this.$emit('sowing-season-details', this.payload);
      },

      create() {
        this.payload = {};
        this.subRegionEdit = "";
        this.formDialog = true;
        this.regionSelected = false;
      },

      edit(item) {
        this.payload = Object.assign({}, item);
        this.subRegionEdit = this.payload.sub_region;
        this.filterSubRegion();
        this.formDialog = true;
        this.openDetails = true;
      },

      deleteItem(item) {
        this.payload = Object.assign({}, item);
        this.showConfirmDestroyDialog = true;
      },

      closeConfirmDestroyDialog() {
        this.showConfirmDestroyDialog = false;
      },

      closeFormDialog() {
        this.formDialog = false;
        this.openDetails = false;
      },

      changeRegion() {
        this.payload.sub_region = "";
        this.componentKey += 1;
        this.filterSubRegion();
      },

      filterSubRegion() {
        const region = this.payload.region;
        const subRegion = this.payload.sub_region;

        const subRegionSelected = this.subRegions.find(item => {
          const { [region] : value } = item;

          if (value !== undefined) return item;
        });

        this.storedSubRegions = this.storedSubRegions.map(item => Number(item));
        const storedSubRegionsFiltered = this.storedSubRegions.filter(item =>
          item !== Number(this.subRegionEdit)
        );

        this.subRegionFiltered = subRegionSelected[[region]];
        this.subRegionFiltered = this.subRegionFiltered.filter(item =>
          !storedSubRegionsFiltered.includes(item.text)
        );

        this.regionSelected = true;
        this.payload.sub_region = parseInt(subRegion);
        this.componentKey += 1;
      },

      save() {
        if (!this.$refs.formSowingSeason.validate()) {
          return;
        }

        this.payload.cultivar_id = this.cultivar_id;

        let loader = this.$loading.show();

        if (!this.openDetails) {
          this.sowingSeasonService.create(this.payload)
          .then(() => {
            loader.hide();
            this.closeFormDialog();
            this.initialize();
            this.$toasted.show('Época cadastrada com sucesso.', {
              type: 'success'
            });
          })
          .catch(({response}) => {
            loader.hide();
            const {error} = response.data;
            this.$toasted.show(error, {
              type: 'error'
            });
          });
        } else {
          this.sowingSeasonService.edit(this.payload)
          .then(() => {
            loader.hide();
            this.initialize();
            this.closeFormDialog();

            this.$toasted.show('Época atualizada com sucesso.', {
              type: 'success'
            });
          })
          .catch(({response}) => {
            loader.hide();
            const {error} = response.data;
            this.$toasted.show(error, {
              type: 'error'
            });
          });
        }
      },

      async destroy() {
        const loader = this.$loading.show();

        await this.sowingSeasonService.delete(this.payload).then(() => {
          loader.hide();
          this.initialize();
          this.showConfirmDestroyDialog = false;
          this.$toasted.show("Época de semeadura removida com sucesso.", {
            type: "success",
          });
        })
        .catch(() => {
          loader.hide();
          this.showConfirmDestroyDialog = false;
          this.$toasted.show("Época de semeadura não foi removida.", {
            type: 'error'
          });
        });
      },

      loadAgain() {
        this.initialize();
      }
    },

    computed: {
      dynamicFormName() {
        if (this.openDetails) {
          return "Editar Época";
        }

        return this.formName;
      }
    },

    beforeMount() {
      this.initialize();
    },
    mounted(){
      this.$refs.test.value="2023-03-03"
    }
  }
</script>

<style scoped>
  .data-table-sowing-seasons {
    border: none !important;
    padding: 0 !important;
    margin-top: 1rem;
  }

  ::v-deep .data-table-sowing-seasons th {
    text-align: center !important;
  }
</style>
