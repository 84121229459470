<template>
  <div>
    <div v-if="openDetails && !openDetailsSowingSeason && !openDetailsDiseaseBehavior"
      class="mt-4 font-weight-bold">
      <v-icon @click="closeView()"> mdi-arrow-left </v-icon>&nbsp;
      <span style="margin-left: 0.5rem;">Voltar</span>
    </div>

    <div v-if="openDetailsSowingSeason" class="mt-4 mb-10 font-weight-bold">
      <v-icon @click="closeViewSowingSeason()"> mdi-arrow-left </v-icon>&nbsp;
      <span style="margin-left: 0.5rem;">Voltar</span>
    </div>

    <div v-if="openDetailsDiseaseBehavior" class="mt-4 mb-10 font-weight-bold">
      <v-icon @click="closeViewDiseaseBehavior()"> mdi-arrow-left </v-icon>&nbsp;
      <span style="margin-left: 0.5rem;">Voltar</span>
    </div>

    <toolbar :toolbarName="dynamicToolbarName" :openDetails="openDetails"
      v-if="!openDetailsSowingSeason && !openDetailsDiseaseBehavior"/>

    <form-dialog :dialog="formDialog" :formName="dynamicFormName">
      <v-form ref="formCultivar">
        <v-row>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="Nome da cultivar"/>
            <simple-text-field v-model="payload.name"
                               required
                               placeholder="Nome"/>
          </v-col>

          <v-col cols="12" md="4" sm="6">
            <custom-label label="Marca"/>
            <simple-select
              v-model="payload.cultivar_brand_id"
              :items="brands"
              itemText="name"
              itemValue="id"
              class="mr-2"
              placeholder="Selecione uma marca"
              :menuProps="{ bottom: true, offsetY: true, closeOnClick: true, maxHeight: 200 }"
              required
              :rules="requiredRules"
            >
            </simple-select>
          </v-col>

          <v-col cols="12" md="4" sm="6">
            <custom-label label="Tecnologia"/>
            <simple-select
              v-model="payload.technology"
              :items="technologies"
              itemText="text"
              itemValue="text"
              class="mr-2"
              placeholder="Selecione uma tecnologia"
              :menuProps="{ bottom: true, offsetY: true, closeOnClick: true, maxHeight: 200 }"
              required
              :rules="requiredRules"
            >
            </simple-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="Grupo de Maturação"/>
              <simple-select
                v-model="payload.maturation_group"
                :items="maturationGroups"
                itemText="text"
                itemValue="text"
                class="mr-2"
                placeholder="Selecione um grupo de maturação"
                :menuProps="{ bottom: true, offsetY: true, closeOnClick: true, maxHeight: 200 }"
                required
                :rules="requiredRules"
              >
              </simple-select>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <custom-label label="Exigência a Fertilidade"/>
              <simple-select
                v-model="payload.fertility_requirement"
                :items="fertilityRequirements"
                itemText="text"
                itemValue="text"
                class="mr-2"
                placeholder="Selecione uma exigência de fertilidade"
                :menuProps="{ bottom: true, offsetY: true, closeOnClick: true, maxHeight: 200 }"
                required
                :rules="requiredRules"
              >
              </simple-select>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <custom-label label="Hábito de Crescimento"/>
              <simple-select
                v-model="payload.growth_habit"
                :items="growthHabits"
                itemText="text"
                itemValue="text"
                class="mr-2"
                placeholder="Selecione um hábito de crescimento"
                :menuProps="{ bottom: true, offsetY: true, closeOnClick: true, maxHeight: 200 }"
                required
                :rules="requiredRules"
              >
              </simple-select>
            </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="PMS"/>
            <simple-text-field v-model="payload.pms"
                                required
                                onlyPositiveNumber
                                type="number"
                                placeholder="PMS"/>
          </v-col>

          <v-col cols="12" md="4" sm="6">
            <custom-label label="Status"/>
            <simple-select
              v-model="payload.is_active"
              :items="statusType"
              itemText="text"
              itemValue="value"
              class="mr-2"
              placeholder="Status"
              :menuProps="{ bottom: true, offsetY: true, closeOnClick: true, maxHeight: 200 }"
            />
          </v-col>

          <v-col cols="12" md="4" sm="6">
            <custom-label label="Cultura"/>
            <simple-select
              v-model="payload.culture_id"
              :items="cultures"
              itemText="name"
              itemValue="id"
              class="mr-2"
              placeholder="Cultura"
              :menuProps="{ bottom: true, offsetY: true, closeOnClick: true, maxHeight: 200 }"
            />
          </v-col>
        </v-row>
      </v-form>
    </form-dialog>

    <search-bar v-if="!openDetails && !openDetailsSowingSeason && !openDetailsDiseaseBehavior"
      v-model="search" @externalFiltersClear="clearFilters" :noSecondaryInput="false">
      <simple-select
          v-model="isActiveSearch"
          :items="statusType"
          @input="filterByStatus()"
          itemText="text"
          itemValue="value"
          class="mr-2"
          placeholder="Status"
          height="0"
          :menuProps="{ bottom: true, offsetY: true, closeOnClick: true, maxHeight: 200 }"
      />
    </search-bar>

    <!-- Cultivar Details -->
    <row-details v-if="openDetails && !openDetailsSowingSeason && !openDetailsDiseaseBehavior"
      :show="openDetails" :showThirdRowDetails="openDetails">
      <v-row align="center">
        <v-col class="d-flex justify-start">
          <span class="font-weight-bold">Detalhes</span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="d-flex justify-end">
          <v-icon @click="edit()"> mdi-pencil</v-icon>
        </v-col>
      </v-row>

      <v-row>
        <col-details column="3" name="Nome" :item="payload.name"/>
        <col-details column="3" name="Marca" :item="payload.cultivar_brand.name"/>
        <col-details column="3" name="Tecnologia" :item="payload.technology"/>
      </v-row>

      <v-row>
        <col-details column="3" name="Grupo de Maturação" :item="payload.maturation_group"/>
        <col-details column="3" name="Exigência a Fertilidade" :item="payload.fertility_requirement"/>
        <col-details column="3" name="PMS(g)" :item="parseFloat(payload.pms) + 'g'"/>
        <col-details column="3" name="Hábito de Crescimento" :item="payload.growth_habit"/>
      </v-row>

      <v-row>
        <col-details column="3" name="Cultura" :item="payload.culture ? payload.culture.name : '--'"></col-details>
      </v-row>

      <template v-slot:[`other-row-details`]>
        <sowing-seasons :cultivar_id="payload.id"
          @sowing-season-details="viewSowingSeason($event)"/>
      </template>

      <template v-slot:[`third-row-details`]>
        <disease-behaviors :cultivar_id="payload.id"
          @disease-behavior-details="viewDiseaseBehavior($event)"/>
      </template>
    </row-details>

    <!-- Sowing Season Details -->
    <row-details v-if="openDetailsSowingSeason">
      <v-row align="center">
        <v-col class="d-flex justify-start">
          <span class="font-weight-bold">Detalhes</span>
        </v-col>
      </v-row>

      <v-row>
        <col-details column="3" name="Região" :item="payloadSowingSeason.region"/>
        <col-details column="3" name="Sub-Região" :item="payloadSowingSeason.sub_region"/>
      </v-row>

      <v-row>
        <col-details column="3" name="População Mínima" :item="payloadSowingSeason.min_population + ' Mil'"/>
        <col-details column="3" name="População Máxima" :item="payloadSowingSeason.max_population + ' Mil'"/>
        <col-details column="3" name="Ciclo Médio" :item="payloadSowingSeason.medium_cycle + ' dias'"/>
      </v-row>

      <v-row>
        <col-details column="6" name="Data de Início do Período Pré Aceitável" :item="payloadSowingSeason.start_pre_acceptable_period | date"/>
        <col-details column="6" name="Data do Fim do Período Pré Aceitável" :item="payloadSowingSeason.end_pre_acceptable_period | date"/>
      </v-row>

      <v-row>
        <col-details column="6" name="Data de Início do Período Recomendado" :item="payloadSowingSeason.start_recommended_period | date"/>
        <col-details column="6" name="Data do Fim do Período Recomendado" :item="payloadSowingSeason.end_recommended_period | date"/>
      </v-row>

      <v-row>
        <col-details column="6" name="Data de Início do Período Pós Aceitável" :item="payloadSowingSeason.start_post_acceptable_period | date"/>
        <col-details column="6" name="Data do Fim do Período Pós Aceitável" :item="payloadSowingSeason.end_post_acceptable_period | date"/>
      </v-row>
    </row-details>

    <!-- Disease Behavior Details -->
    <row-details v-if="openDetailsDiseaseBehavior">
      <v-row align="center">
        <v-col class="d-flex justify-start">
          <span class="font-weight-bold">Detalhes</span>
        </v-col>
      </v-row>

      <v-row>
        <col-details column="4" name="Nome da Doença" :item="payloadDiseaseBehavior.disease.name"/>
        <col-details column="4" name="Categoria" :item="payloadDiseaseBehavior.resistance_scale"/>
      </v-row>
    </row-details>

    <data-table :headers="headers"
                :fetching="fetching"
                :data="data"
                :search="search.text"
                :objectName="objectName"
                @loadAgain="loadAgain"
                v-else-if="!openDetails && !openDetailsSowingSeason && !openDetailsDiseaseBehavior"
    >
      <template v-slot:[`item.is_active`]="{ item }">
        {{ getStatus(item.is_active) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon style="color:#707070;" class="ml-4" small @click="view(item)">
          mdi-information-outline
        </v-icon>
        <v-icon class="ml-4" small @click="deleteItem(item)">
          mdi-trash-can-outline
        </v-icon>
      </template>
    </data-table>

    <confirm-destroy-dialog
      :title="payload.name"
      :dialog.sync="showConfirmDestroyDialog"
      @closeDialog="closeConfirmDestroyDialog"
    />
  </div>
</template>

<script>
  import Toolbar from "@/components/crud/Toolbar.vue";
  import FormDialog from "@/components/crud/FormDialog.vue";
  import SearchBar from "@/components/layouts/SearchBar.vue";
  import SimpleSelect from "@/components/inputs/SimpleSelect.vue";
  import DataTable from "@/components/data-table/DataTable.vue";
  import SimpleTextField from "@/components/inputs/SimpleTextField.vue";
  import CustomLabel from "@/components/texts/CustomLabel";
  import RowDetails from "@/components/crud/Details";
  import ColDetails from "@/components/crud/DetailsItem";
  import ConfirmDestroyDialog from "@/components/dialogs/ConfirmDestroyDialog";

  import Cultivars from "@/domain/cultivars/cultivars.js";
  import CultivarBrands from "@/domain/cultivar-brands/cultivar-brands";
  import CulturesService from "@/domain/cultures/cultures.service";

  import SowingSeasons from "@/views/pages/cultivars/SowingSeasons.vue";
  import DiseaseBehaviors from "@/views/pages/cultivars/DiseaseBehaviors.vue";

  import eventBus from "@/event-bus.js";

  export default {
    name: 'Cultivars',

    components: {
      Toolbar,
      FormDialog,
      SearchBar,
      SimpleSelect,
      DataTable,
      SimpleTextField,
      CustomLabel,
      RowDetails,
      ColDetails,
      SowingSeasons,
      DiseaseBehaviors,
      ConfirmDestroyDialog
    },

    data() {
      return {
        toolbarName: 'Cadastro de Cultivares',
        openDetails: false,
        openDetailsSowingSeason: false,
        openDetailsDiseaseBehavior: false,
        formDialog: false,
        formName: 'Adicionar Nova Cultivar',
        objectName: 'cultivares',

        statusType: [
          {text: "Ativo", value: true},
          {text: "Inativo", value: false}
        ],

        headers: [
          {text: 'ID', align: 'start', value: 'id'},
          {text: 'Nome', value: 'name'},
          {text: 'Marca', value: 'cultivar_brand.name'},
          {text: 'Tecnologia', value: 'technology'},
          {text: 'Status', value: 'is_active',
            filter: value => {
              if (this.isActiveSearch === '') return true;
              return value === this.search.is_active;
            }
          },
          {text: 'Detalhes', value: 'actions', sortable: false}
        ],

        isActiveSearch: '',
        search: {},
        fetching: true,
        data: [],
        payload: {},
        payloadSowingSeason: {},
        payloadDiseaseBehavior: {},
        brands: [],
        cultures:[],
        maturationGroups: [],

        technologies: [
          {text: 'RR'},
          {text: 'Intacta'},
          {text: 'Intacta 2 Xtend'},
          {text: 'Inox'},
          {text: 'Enlist E3'},
          {text: 'Conkesta E3'}
        ],

        fertilityRequirements: [
          {text: 1}, {text: 2}, {text: 3}, {text: 4}, {text: 5}
        ],

        growthHabits: [
          {text: 'Determinado'},
          {text: 'Indeterminado'}
        ],

        cultivarService: null,
        cultivarBrandService: null,
        culturesService:null,
        showConfirmDestroyDialog: false,

        requiredRules: [v => (!!v || v === false) || "O campo é obrigatório"]
      };
    },

    methods: {
      getCultivars() {
        this.fetching = true;
        this.data = [];

        this.cultivarService.list().then(result => {
          result.data.map(item => {
            this.data.push(item);
          });
        })
        .catch(({response}) => {
          const {error} = response.data;
          this.$toasted.show(error, {
            type: 'error'
          });
        });

        this.fetching = false;
      },

      getCultivarBrands() {
        this.fetching = true;
        this.brands = [];

        this.cultivarBrandService.all().then(result => {
          result.data.map(item => {
            this.brands.push(item);
          });
        });

        this.fetching = false;
      },

      getCultures(){
        this.fetching = true;
        this.cultures = [];
        
        this.culturesService.list().then(res =>{
          this.cultures= res.data.data
        })

        this.fetching = false;
      },

      getStatus(status) {
        if (status === true) return "Ativo";
        return "Inativo";
      },

      initialize() {
        this.cultivarService = Cultivars;
        this.cultivarBrandService = CultivarBrands;
        this.culturesService = CulturesService;
        this.payload = this.cultivarService.newData();

        this.rangeMaturationGroup(5, 10);

        this.getCultivars();
        this.getCultivarBrands();
        this.getCultures();
      },

      rangeMaturationGroup(start, end) {
        for(let cont=start; cont<=end; cont+=0.1) {
          this.maturationGroups.push({text: cont.toFixed(1)});
        }
      },

      view(item) {
        this.payload = Object.assign({}, item);
        this.openDetails = true;
      },

      viewSowingSeason(item) {
        this.openDetailsSowingSeason = true;
        this.openDetailsDiseaseBehavior = false;
        this.payloadSowingSeason = Object.assign({}, item);
      },

      viewDiseaseBehavior(item) {
        this.openDetailsSowingSeason = false;
        this.openDetailsDiseaseBehavior = true;
        this.payloadDiseaseBehavior = Object.assign({}, item);
      },

      create() {
        this.payload = {};
        this.payload.is_active = true;
        this.formDialog = true;
      },

      edit() {
        this.formDialog = true;
      },

      deleteItem(item) {
        this.payload = Object.assign({}, item);
        this.showConfirmDestroyDialog = true;
      },

      closeConfirmDestroyDialog() {
        this.showConfirmDestroyDialog = false;
      },

      closeFormDialog() {
        this.formDialog = false;
      },

      closeView() {
        this.openDetails = false;
        this.initialize();
      },

      closeViewSowingSeason() {
        this.openDetailsSowingSeason = false;
      },

      closeViewDiseaseBehavior() {
        this.openDetailsDiseaseBehavior = false;
      },

      save() {
        if (!this.$refs.formCultivar.validate()) {
          return;
        }

        let loader = this.$loading.show();

        if (!this.openDetails) {
          this.cultivarService.create(this.payload)
          .then(() => {
            loader.hide();
            this.closeFormDialog();
            this.initialize();
            this.$toasted.show('Cultivar cadastrado com sucesso.', {
              type: 'success'
            });
          })
          .catch(({response}) => {
            loader.hide();
            const {error} = response.data;
            this.$toasted.show(error, {
              type: 'error'
            });
          });
        } else {
          this.cultivarService.edit(this.payload)
          .then(() => {
            loader.hide();

            const brandSelected = this.brands.find(
              item => item.id === this.payload.cultivar_brand_id
            );

            const cultureSelected = this.cultures.find( culture => culture.id ===this.payload.culture_id)

            if(brandSelected) {
              this.payload.cultivar_brand.name = brandSelected.name;
            }
            if(cultureSelected) {
              this.payload.culture = cultureSelected;
            }

            this.closeFormDialog();

            this.$toasted.show('Cultivar atualizado com sucesso.', {
              type: 'success'
            });
          })
          .catch(({response}) => {
            loader.hide();
            const {error} = response.data;
            this.$toasted.show(error, {
              type: 'error'
            });
          });
        }
      },

      async destroy() {
        const loader = this.$loading.show();

        await this.cultivarService.delete(this.payload).then(() => {
          loader.hide();
          this.getCultivars();
          this.showConfirmDestroyDialog = false;
          this.$toasted.show("Cultivar removido com sucesso.", {
            type: "success",
          });
        })
        .catch(() => {
          loader.hide();
          this.showConfirmDestroyDialog = false;
          this.$toasted.show("Cultivar não foi removido.", {
            type: 'error'
          });
        });
      },

      filterByStatus() {
        this.search.is_active = this.isActiveSearch;
      },

      clearFilters() {
        this.search = {};
        this.isActiveSearch = "";
      },

      loadAgain() {
        this.initialize();
      }
    },

    computed: {
      dynamicToolbarName() {
        if (this.openDetails) {
          return 'Detalhes do Cultivares / ' + this.payload.name;
        }

        return this.toolbarName;
      },

      dynamicFormName() {
        if (this.openDetails) {
          return "Editar Cultivares";
        }

        return this.formName;
      }
    },

    beforeMount() {
      this.initialize();
    },

    mounted() {
      eventBus.showPanelIndexes(false);
    }
  }
</script>
