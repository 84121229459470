<template>
  <v-text-field
      v-model="currentValue"
      dense
      hide-details="auto"
      outlined
      class="custom-input"
      type="text"
      :required="required"
      :rules="getRules"
      :placeholder="placeholder"
      :height="height"
      :disabled="disabled"
      @input="onlyNumbers"
      v-mask="getMask()"
  />
</template>

<script>
import moment from 'moment';

export default {
  name: "SimpleDateField",
  props: {
    value: {
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '48px',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentValue: {
      get() {
        return this.value ?? "";
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    getRules() {
      const rules = [];
      if (this.required) rules.push(this.rulesNative.required);
      rules.push(this.rulesNative.dayMounth);
      return rules;
    },
  },
  data() {
    return {
      isValid: true,
      rulesNative: {
        required: (value) => !!value || "O campo é obrigatório",
        dayMounth: (value) => {
            if(value.length===5)
                return (moment(value,'DD-MM')._isValid) || "Data inválida"
            return true
        }
      },
    };
  },
  methods: {
    onlyNumbers() {
      this.$emit('onlyNumbers');
    },
    getMask() {
         return ['##/##'];      
    },

    getKeyUp() {
      this.$emit('custom-key-up');
    }
  }
};
</script>

<style>
.custom-input {
  border-radius: 15px !important;
}

.custom-input input {
  padding: 0 10px !important;
}

.custom-input fieldset {
  border: 1px solid #e3e3e3;
  border-color: #FEBC00 !important;
}

input::-webkit-datetime-edit-year-field{ color: purple; }
</style>