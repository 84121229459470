import http from '@/services/http.service';

const apiUrl = '/diseases';

class DiseasesService {
    static list(params) {
        return http.get(`${apiUrl}`, { params });
    }

    static listByField(fieldId) {
        return http.get(`${apiUrl}/fields/${fieldId}`);
    }

    static listNonResistantByCultivar(cultivarId, fieldId) {
        return http.get(`${apiUrl}/cultivars/${cultivarId}/fields/${fieldId}`);
    }
}

export default DiseasesService;
