import { render, staticRenderFns } from "./DiseaseBehaviors.vue?vue&type=template&id=6c7a68f8&scoped=true&"
import script from "./DiseaseBehaviors.vue?vue&type=script&lang=js&"
export * from "./DiseaseBehaviors.vue?vue&type=script&lang=js&"
import style0 from "./DiseaseBehaviors.vue?vue&type=style&index=0&id=6c7a68f8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c7a68f8",
  null
  
)

export default component.exports